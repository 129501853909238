<template>
   <el-row class="cont copy-mail-box">
      <!-- 面包屑 bg-gradient -->
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>营销云</el-breadcrumb-item>
            <el-breadcrumb-item>营销</el-breadcrumb-item>
            <el-breadcrumb-item to="/mail">邮件</el-breadcrumb-item>
            <el-breadcrumb-item>复制</el-breadcrumb-item>
         </el-breadcrumb>
         <el-button class="bg-gradient" type="primary" @click="addbegin('DRAFT')">保存</el-button>
      </el-row>
      <!-- 返回页头 -->
      <el-page-header @back="goBack" content="新建酒店"></el-page-header>
      <el-row class="cont-form-box">

         <el-steps :active="active" simple>
            <el-step title="01.设置活动消息"></el-step>
            <el-step title="02.目标用户"></el-step>
            <el-step title="03.如何触达人群"></el-step>
            <el-step title="04.发送什么内容"></el-step>
            <el-step title="05.预览"></el-step>
         </el-steps>
         <!-- 第一步 -->
         <el-row class="content" v-show="active == 0">
            <ul>
               <li>
             <span>活动名称</span
             ><el-input v-model="eventName" ></el-input>
               </li>
               <li>
                  <span>转化跟踪</span>
                  <p @click="onoff(Conversiontracking)">
                     <el-switch
                           v-model="Conversiontracking"
                           active-color="#13ce66"
                           inactive-color="#ff4949"
                     ></el-switch>
                  </p>
                  <el-popover
                        placement="top-start"
                        title="转化跟踪"
                        width="200"
                        trigger="hover"
                        content="在活动中开启转化跟踪后，可以设置一个事件作为转化目标，系统会自动生成一个转化漏斗并计算转化率。在活动中，
               一般设置落地页后续的某个业务事件作为目标，比如：加入购物车、提交订单、报名参与等。"
                  >
                     <el-button slot="reference" class="btn"
                     ><i class="el-icon-question"></i
                     ></el-button>
                  </el-popover>
               </li>
               <li v-show="Conversiontracking == true">
                  <span>转化目标</span>
                  <el-popover placement="right" width="400" trigger="hover">
                     <el-row>
                        <el-row class="list">
                           <p
                                 :class="goalid == item.id ? 'action' : ''"
                                 v-for="item in goals"
                                 :key="item.id"
                                 @click="goalsa(item.id,item.name)"
                           >
                              {{ item.name }}
                           </p>
                        </el-row>
                        <el-button
                              type="primary"

                              class="el-icon-refresh-left refresh"
                              plain
                              @click="Goal()"
                        >刷新</el-button
                        >
                     </el-row>
                     <el-input slot="reference" v-model="uname" ></el-input>
                  </el-popover>
               </li>
               <li v-show="Conversiontracking == true">
                  <el-row>
                     <el-row
                     ><span>转化周期</span
                     ><el-input
                           v-model="period"

                           placeholder="输入周期"
                     ></el-input
                     ><span>天内</span></el-row
                     >
                  </el-row>
               </li>
            </ul>
            <el-button
                  type="primary"
                  class="bg-gradient"
                  style="margin-top: 12px; margin-bottom: 1rem"
                  @click="next()"
            >下一步</el-button
            >
         </el-row>

         <!-- 第二步 -->
         <el-row class="targetuser" v-show="this.active == 1">
            <el-row style="height: 100%; float: left">
               <el-row class="target">目标用户</el-row>
               <el-row class="borders">
                  <span>和</span>
               </el-row>
            </el-row>
            <el-row style="width: 20%; float: left">
               <el-row style="width: 100%">
                  <el-row v-for="(tablist,index) in multipleclusters" :key="index" style="width:100%; text-align:right; display: flex">
                     <el-select v-model="tablist.id" :disabled="tablist.check"  placeholder="请选择目标用户" @change="selectgroup(tablist,index)">
                        <el-option
                              v-for="item in usergroup"
                              :key="item.id"
                              :label="item.groupName"
                              :value="item.id"
                              :disabled="item.check"
                        >
                        </el-option>
                     </el-select>
                     <i class="el-icon-error" @click="deleteObject(tablist,index)" v-show="multipleclusters.length != 0 " style="margin-top: 10px"></i>
                  </el-row>
               </el-row>
            </el-row>
            <el-row class="add">
               <el-button
                     class="buttons"
                     type="primary"

                     :disabled="btnDisabled"
                     plain
                     @click="addAgroup()"
               >添加分群</el-button
               >
               <el-popover
                     placement="top-start"
                     title="目标用户"
                     width="200"
                     trigger="hover"
                     content="活动的目标受众，最终收到触达消息的人群分组。可以选择系统默认的所有用户、新访问用户，也可以选择方舟智能分析中创建的用户分群或者是通过 API 对接的任何第三方用户分组信息。"
               >
                  <el-button slot="reference" class="ioc"
                  ><i class="el-icon-question"></i
                  ></el-button>
               </el-popover>
            </el-row>
            <el-row>
               <el-button style="margin-top: 12px"  @click="laststep()"
               >上一步</el-button
               >
               <el-button
                     style="margin-top: 12px"
                     type="primary"
                     class="bg-gradient"
                     @click="next()"
               >下一步</el-button
               >
            </el-row>
            <el-row class="Thetotaluser">
               <el-row>总用户数</el-row>
               <el-row class="amount"
               ><h1>{{ total }}</h1>
                  人</el-row
               >
            </el-row>
         </el-row>

         <!-- 第三步 -->
         <el-row v-show="active == 2">
            <el-row class="thethirdstep">
               <dl>
                  <dt class="Pushtherules">
                     <span>推送规则</span>
                     <el-radio v-model="Pushrules" :label="1">仅发送一次</el-radio>
                     <el-radio v-model="Pushrules" :label="2">基于用户事件触发</el-radio>
                     <!-- <el-radio v-model="Pushrules" :label="3">周期</el-radio> -->
                  </dt>
                  <dd>
                     <el-row v-show="Pushrules == 1">
                        <el-select v-model="isWaitSend"  placeholder="请选择">
                           <el-option
                                 v-for="item in sends"
                                 :key="item.value"
                                 :label="item.label"
                                 :value="item.value"
                           >
                           </el-option>
                        </el-select>

                        <el-row v-show="isWaitSend == 'YES'">
                           <el-row class="settime">
                              <span>消息将于</span>
                              <el-date-picker
                                    v-model="time"
                                    type="datetime"
                                    placeholder="选择日期时间"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    style="margin: 0px 1rem"

                              >
                              </el-date-picker>

                              <span>准时发送</span>
                           </el-row>
                           <el-row
                           ><el-input

                                 placeholder="注：选择定时发送时，可选时段不能超过当前时点48 小时"
                                 style="width: 30%"
                                 :disabled="true"
                           ></el-input
                           ></el-row>
                        </el-row>
                     </el-row>

                     <el-row v-show="Pushrules == 2" class="Userevents">
                        <el-row>
                           <span>从</span>
                           <el-date-picker
                                 v-model="beginDate"
                                 type="datetime"
                                 placeholder="选择开始时间"
                                 value-format="yyyy-MM-dd HH:mm:ss"
                                 style="margin: 0px 1rem"

                           >
                           </el-date-picker>
                           <span>到</span>
                           <el-date-picker
                                 v-model="endDate"
                                 type="datetime"
                                 placeholder="选择结束时间"
                                 value-format="yyyy-MM-dd HH:mm:ss"
                                 style="margin: 0px 1rem"

                           >
                           </el-date-picker>

                           <span>时间范围内</span>
                        </el-row>

                        <el-row class="users">
                           <span class="haddone">当用户做过</span>
                           <el-col style="float: left; width: 25%;">
                              <el-row v-for="(iten,index) in addPulldown" :key="index" style="width: 100%; margin-bottom: 1rem;">
                                 <el-select v-model="iten.triggerId"  placeholder="请选择" style="width: 65%;">
                                    <el-option
                                          v-for="item in dropDownbox"
                                          :key="item.id"
                                          :label="item.name"
                                          :value="item.id"
                                    >
                                    </el-option>
                                 </el-select>

                                 <el-tooltip class="item" effect="dark" content="删除" placement="bottom-end">
                                    <el-button class="el-icon-circle-close"  @click="expurgate()"></el-button>
                                 </el-tooltip>

                                 <el-tooltip
                                       class="item"
                                       effect="dark"
                                       content="新增"
                                       placement="top-start"
                                 >
                                    <el-button
                                          style="margin-left: 0px;"
                                          class="el-icon-circle-plus-outline"

                                          @click="pulldown()"
                                    ></el-button>
                                 </el-tooltip>
                              </el-row>
                           </el-col>
                        </el-row>

                        <el-row class="dispatch">
                           <el-select
                                 v-model="isWaitSend"

                                 style="width: 10%"
                                 placeholder="请选择"
                           >
                              <el-option
                                    v-for="item in immediatelyAwait"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                              >
                              </el-option>
                           </el-select>
                           <span
                                 v-show="isWaitSend == 'NO'"
                                 style="font-size: 14px; color: #999"
                           >发送</span
                           >

                           <el-input
                                 v-show="isWaitSend == 'YES'"
                                 style="width: 70px; margin-left: 1rem"

                                 v-model="time"
                                 placeholder="时间"
                           ></el-input>
                           <el-select
                                 v-show="isWaitSend == 'YES'"
                                 v-model="timedivision"

                                 style="width: 90px"
                           >
                              <el-option
                                    v-for="item in timedivisions"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                              >
                              </el-option>
                           </el-select>
                           <span v-show="isWaitSend == 'YES'">准时发送</span>
                        </el-row>
                        <el-row style="margin-top: 1rem" v-show="isWaitSend == 'YES'">
                           <el-input
                                 style="width: 31.5%"

                                 placeholder="注：分钟最大值为2879分钟、小时最大值为47小时、天最大值为1天"
                                 :disabled="true"
                           ></el-input>
                        </el-row>
                     </el-row>
                  </dd>
               </dl>
               <el-row style="width: 90%; margin: auto">
                  <el-button style="margin-top: 12px"  @click="laststep()"
                  >上一步</el-button
                  >
                  <el-button
                        type="primary"
                        style="margin-top: 12px"
                        class="bg-gradient"
                        @click="next()"
                  >下一步</el-button
                  >
               </el-row>
            </el-row>
         </el-row>

         <!-- 第四步 -->
         <el-row v-show="active == 3" style="height: 75%;">
            <el-row class="boxformboard" v-show="modulesa == false">
               <el-row class="formboard" v-for="(item,index) in templatelist" :key="index">
                  <p @click="richtexts(true,item,'修改')">
                     <el-row class="multilinetext">
                        <el-row class="contentas">{{ item.content }}</el-row>
                     </el-row>
                  </p>
               </el-row>
               <el-row>
                  <el-row  style="margin:2rem;">
                     <div  @click="richtexts(true,'1','创建')">
                        <el-row class="addemailtemplate">
                           <el-row>
                              <i class="el-icon-circle-plus"></i>
                              <p>新建邮件模板</p>
                           </el-row>
                        </el-row>
                     </div>
                  </el-row>
               </el-row>
            </el-row>
            <el-row v-show="modulesa == true">
               <el-row class="toolbar">
                  <el-row class="titles">
                     <p style="float:left;"><b>发送内容</b></p>
                     <p style="float:right; position: relative; z-index: 20" @click="richtexts(false,'3','4')"><a style="text-decoration:none;" href="#">更换模块</a></p>
                  </el-row>
                  <el-row class="addresser">
                     <el-row class="oneemail">邮件类型</el-row>
                     <el-row style="margin-top: 10px">
                        <el-radio v-model="numbers" label="1">163邮件</el-radio>
                        <el-radio v-model="numbers" label="2">QQ邮件</el-radio>
                     </el-row>

                  </el-row>
                  <el-row class="addressers">
                     <el-row class="oneemail">发件人名字</el-row>
                     <el-input v-model="username"  placeholder="请输入发件人姓名"></el-input>
                  </el-row>
                  <el-row class="addressers">
                     <el-row class="oneemail">邮件标题</el-row>
                     <el-input v-model="mailtitle"  placeholder="请输入"></el-input>
                  </el-row>
               </el-row>
               <vue-tinymce style="width:80%; height:555px; float:right;" v-model="richtext" :setting="setting" />
               <el-row style="padding-left: 2rem;">
                  <!--          <el-button-->
                  <!--              style="margin-top: 2rem; margin-bottom: 2rem"-->
                  <!--              -->
                  <!--              type="primary"-->
                  <!--              @click="saveTemplate({numbers,username,mailtitle,richtext,id})">保存</el-button>-->
                  <el-button
                        type="primary"
                        style="margin-top: 12px"
                        class="bg-gradient"
                        @click="next()">下一步</el-button>
               </el-row>
            </el-row>
            <el-button
                  v-show="modulesa == false"
                  style="margin-top: 2rem; margin-bottom: 2rem"

                  @click="laststep()"
            >上一步</el-button
            >
         </el-row>
         <!-- 第五步 next -->
         <el-row v-show="active == 4" style="height: 75%; background: #e2e3e7;">
            <el-row class="textcontent">

            </el-row>
            <el-row class="Theolddata">
               <ul>
                  <li>
                     <span class="designation">活动名称:</span>
                     <span>{{ eventName }}</span>
                  </li>
                  <li>
                     <span class="designation">目标用户:</span>
                     <span v-for="(item, index) in multipleclusters" :key="index">{{ item.groupName }},</span>
                  </li>
                  <li>
                     <span class="designation">推送规则:</span>
                     <span>{{ Pushrules | Pushrulesa }}</span>
                  </li>
                  <li>
                     <span class="designation">邮箱类型:</span><span>{{ numbers }}</span>
                  </li>
                  <li>
                     <span class="designation">发件人姓名:</span><span>{{ username }}</span>
                  </li>
                  <li>
                     <span class="designation">邮件标题:</span><span>{{ mailtitle }}</span>
                  </li>
                  <li>
                     <el-button style="margin-top: 12px" @click="laststep()"
                     >上一步</el-button
                     >
                     <el-button
                           class="el-icon-s-promotion kaishi"

                           @click="addbegin('IN_PROGRESS')"
                     >开 始</el-button
                     >
                     <el-button
                           type="primary"
                           class="el-icon-mobile testequipment"

                     >发送到测试设备</el-button
                     >
                  </li>
               </ul>
            </el-row>
         </el-row>
      </el-row>
   </el-row>
</template>

<script>
import { urlObj } from "@/api/interface";
import { marketing } from '@/api/interface/smtech'
import { label } from "@/api/interface/data";
import { mapState } from 'vuex'
   export default {
      watch: {
         hotelInfo: {
            handler(newVal, oldVal) {
               if (newVal.id !== oldVal.id  && oldVal) {
                  this.hotelId = this.hotelInfo.id
                  this.Goal();
                  this.wechatlabel();
                  this.mailList()
               }
            },
            deep: true
         },
         textType(){
            this.pagePath = ""
         }
      },
      computed: {
         ...mapState(['hotelInfo'])
      },
   data() {
      let _this = this
      return {
         templatelist:[],
         richtext:'',
         Modulations:'',
         modulesa:false,
         total:0,
         btnDisabled:false,
         multipleclustera:[],
         title: "",
         period: "",
         active: 0,
         Conversiontracking: false,
         dialogTableVisible: false,
         activeIndex: "1",
         type: 1,
         username:'ffff',          //发件人姓名
         mailtitle:'ttttt',        //邮件标题
         Paths: "",
         sends: [
            { value: "NO", label: "立即发送" },
            { value: "YES", label: "定时发送" },
         ],
         immediatelyAwait: [
            { label: "立即", value: "NO" },
            { label: "等待", value: "YES" },
         ],
         isWaitSend: "NO",
         timedivisions: [
            { label: "分钟", value: "MINUTE" },
            { label: "小时", value: "HOUR" },
            { label: "天", value: "DAY" },
         ],
         input:'',
         time: "",
         timedivision: "",
         search: "", //用户群组
         userid: [],
         eventName: "", //活动名称
         companyId: "", //租户ID
         AppID: "appid", //小程序AppID
         Pushrules: 1,
         urls: "",
         tailafter: "NO",
         beginDate: "", //触发开始时间
         endDate: "", //触发结束时间
         dropDownbox: [],
         triggerId: "",
         file: "", //文件
         limit: 20,
         page: 1,
         usergroup: [], //用户群组
         clusterName: "", //用户群组名
         memberCount: "", //总用户数
         goals: "", //转化目标
         goalid: "", //转化目标ID
         contents: "",
         menuList: [],
         multipleclusters: [],
         bools: false,
         uname:'',
         setting: {
            menubar: false,
            toolbar:
                  "undo redo | code | fullscreen | formatselect alignleft aligncenter alignright alignjustify | link unlink | numlist bullist | image media table | fontselect fontsizeselect forecolor backcolor | bold italic underline strikethrough | indent outdent | superscript subscript | removeformat |",
            toolbar_drawer: "sliding",
            quickbars_selection_toolbar:
                  "removeformat | bold italic underline strikethrough | fontsizeselect forecolor backcolor",
            plugins: "link image table lists fullscreen quickbars code",
            language: "zh_CN",
            height: 350,
            images_upload_handler: function (blobInfo, succFun, failFun) {
               const url = urlObj.upload;
               var file = blobInfo.blob(); //转化为易于理解的file对象
               let param = new FormData();
               param.append("file", file);
               param.append("module", "faq");
               param.append("menu", "manageQA");
               param.append("func", "image");
               param.append("category", "image");
               param.append("isThumb", true);
               if (_this.bizId) {
                  param.append("bizId", _this.bizId);
               } else {
                  param.append("origin", "GBIZID");
               }
               this.$axios.post(url, param, 'file')
                     .then((res) => {
                        if (res.success) {
                           let data = res.records;
                           let imgUrl = window.getResourcesHost() + data.location + "/" + data.formatName;
                           _this.bizId = data.bizId;
                           succFun(imgUrl);
                        }
                     });
            },
         },
         numbers:'',
         accountType: '',
         addPulldown:[],
         hotelId:'',
         createChange:'',
         id:'',
         ids:'',
      };
   },
   mounted() {
      this.hotelId = this.hotelInfo.id
      this.companyId = JSON.parse(sessionStorage.getItem("userInfo")).tenantId || ""
      this.accountType = JSON.parse(sessionStorage.getItem('userInfo') || '{}').accountType
      this.Goal();
      this.wechatlabel();
      this.olddata()
   },
   created() {
      this.multipleclusters = this.$route.query.eventGroupRefList
   },
   methods: {
      olddata(){
         this.eventName =  this.$route.query.eventName
         let Conversiontracking = this.$route.query.isConvert
         if (Conversiontracking == 'YES') {
            this.Conversiontracking = true;
         } else {
            this.Conversiontracking = false;
         }
         let PushRules = this.$route.query.pushRule
         if (PushRules == "USER_EVENT") {
            this.Pushrules = 2;
         }
         if (PushRules == "ONCE") {
            this.Pushrules = 1;
         }
         this.isWaitSend = this.$route.query.isWaitSend
         this.period = this.$route.query.days
         this.endDate = this.$route.query.endDate
         this.beginDate = this.$route.query.beginDate
         this.ids = this.$route.query.id
      },
      //新增下拉框
      addAgroup(){
         this.multipleclusters.push({})
         if (this.multipleclusters.length == this.usergroup.length || this.usergroup.length == 0){
            this.btnDisabled = true
         }
      },
      //选中的时候
      selectgroup(row,val){
         this.total = 0
         this.usergroup.map(i=>{
            this.multipleclusters.map(index=>{
               if (index.id == i.id){
                  i.check = true
                  row.check = true
                  index.groupName = i.groupName
                  index.memberCount = i.memberCount
                  this.total+= index.memberCount
               }
            })
         })
      },
      //删除下拉框
      deleteObject(val,j){
         this.total = 0
         for (var i =0;i<this.usergroup.length;i++){
            if (this.usergroup[i].id == val.id){
               this.usergroup[i].check =false
            }
         }

         for (var z = 0;z<this.multipleclusters.length;z++){
            if (val.id == undefined){
               this.multipleclusters.splice(j,1)
               if (this.multipleclusters.length != this.usergroup.length || this.usergroup.length != 0){
                  this.btnDisabled = false
               }
            }else if (val.id != undefined && this.multipleclusters[z].id == val.id)
               this.multipleclusters.splice(z,1)
            if (this.multipleclusters.length != this.usergroup.length || this.usergroup.length != 0){
               this.btnDisabled = false
            }
            this.total += this.multipleclusters[z].memberCount
         }
      },
      //群组ID
      usersid() {
         this.multipleclusters.map((item) => {
            this.usergroup.map((tab) => {
               if (item.userid == tab.id) {
                  this.memberCount = tab.memberCount;
               }
            });
         });
      },
      richtexts(bool,data,identification){
         this.createChange = identification
         this.modulesa = bool
         if (data.server == 'QQ_MAIL'){
            this.numbers = '2'
         }else if(data.server == 'NETEASE_MAIL'){
            this.numbers = '1'
         }
         this.richtext = data.content
         this.username = data.sender
         this.mailtitle = data.name
         this.id = data.id
         if (data == '3'){
            this.numbers = ''
            this.richtext = ''
            this.username = ''
            this.mailtitle = ''
            this.id = ''
         }
         this.$forceUpdate()
      },
      //创建邮件模板
      saveTemplate(obj){
         console.log(obj.id)
         let num = obj.richtext
         let a = num.slice(3,num.length-4)
         obj.richtext = a
         this.Modulations = a
         if (this.numbers == 2){
            this.numbers = 'QQ_MAIL'
         }else if(this.numbers == 1){
            this.numbers = 'NETEASE_MAIL'
         }
         if (this.createChange == '创建') {
            const url = urlObj.templateEmail;
            const param = {
               hotelId: this.hotelId,
               name: this.mailtitle,
               sender: this.username,
               server: this.numbers,
               content: this.Modulations
            };
            this.$axios.post(url, param).then((res) => {
               if (res.status == 200) {
                  this.$message({
                     message: '创建成功',
                     type: 'success'
                  });
                  this.mailList()
                  this.modulesa = false
               }
            });
         }
         if (this.createChange == '修改'){
            const url = marketing.Modifymailtemplate;
            const param = {
               id:this.id,
               hotelId: this.hotelId,
               name: this.mailtitle,
               sender: this.username,
               server: this.numbers,
               content: this.Modulations
            };
            this.$axios.post(url, param).then((res) => {
               if (res.status == 200) {
                  this.$message({
                     message: '修改成功',
                     type: 'success'
                  });
                  this.mailList()
                  this.modulesa = false
               }
            });
         }
         this.addbegin('IN_PROGRESS')
      },
      //邮件模板列表
      mailList(){
         const url = urlObj.mailingList;
         const param = {
            hotelId: this.hotelId,
            limit:this.limit,
            page: this.page
         };
         this.$axios.post(url, param).then((res) => {
            this.templatelist = res.records
         });
      },
      //查询元数据列表
      metadataLists() {
         const url = urlObj.metadataList;
         const param = {
            state: true,
         };
         this.$axios.post(url, param).then((res) => {
            this.dropDownbox = res.records;
            let triggerId = this.$route.query.triggerId
            // console.log(triggerId)
            this.dropDownbox.forEach(item=>{
               if (item.id == triggerId){
                  // console.log(item.name)
                  this.addPulldown.push({triggerId:item.name})
               }
            })
         });
      },

      //转化目标
      Goal() {
         const url = urlObj.metadataList;
         const param = {
            category: "CONVERSION_EVENTS",
         };
         this.$axios.post(url, param).then((res) => {
            this.goals = res.records;
            let goa = this.$route.query.targetId
            this.goals.forEach(item=>{
               if(item.id == goa){
                  console.log(item.name)
                  this.uname = item.name
                  this.goalid = item.id
               }
            })
         });
      },
      goalsa(id,name) {
         this.goalid = id;
         this.uname = name
         this.goals.forEach((item) => {
            this.$set(item, "color", true);
         });
      },
      //用户群组
      wechatlabel() {
         const url = label.tagGroup;
         const param = {
            hotelId: this.hotelId,
            groupSource: "EMAIL",
            limit: this.limit,
            page: this.page,
         };
         this.$axios.post(url, param).then((res) => {
            this.usergroup = res.records;
            this.usergroup.forEach(item=>{
               this.multipleclusters.forEach((items,index)=>{
                  if (item.id == items.groupId){
                     this.multipleclusters[index] = item
                     this.multipleclusters[index].check = true
                     this.total+= this.multipleclusters[index].memberCount
                  }
               })
            })
         });
      },
      pulldown(){
         this.addPulldown.push({})
      },
      expurgate(){
         this.addPulldown.splice(1,1)
      },
      // 返回上页
      goBack() {
         this.$router.go(-1)
      },

      //下一步
      next() {
         // if (this.active++ > 4) this.active = 0;
         // console.log(this.active)
         if (this.active == 0) {
            if (this.eventName == "") {
               this.$message.error("活动名称不能为空!");
               this.active = 0;
               return;
            }
            if (this.Conversiontracking == true) {
               if (this.goalid == "") {
                  this.$message.error("转化目标不能为空!");
                  this.active = 0;
                  return;
               }
               if (this.period == "") {
                  this.$message.error("转化周期不能为空!");
                  this.active = 0;
                  return;
               }
            }
            return this.active++;
         }
         if (this.active == 1) {
            this.metadataLists();
            if (this.total == 0) {
               this.$message.error("目标用户不能为空!");
               this.active = 1;
               return;
            }
            return this.active++;
         }
         if (this.active == 2) {
            this.mailList()
            if (this.Pushrules == 2) {
               if (this.isWaitSend == "YES") {
                  if (this.time == "") {
                     this.$message.error("发送日期不能为空!");
                     this.active = 2;
                     return;
                  }
               }
               return this.active++;
            }
            if (this.Pushrules == 2) {
               if (this.beginDate == "" && this.endDate == "") {
                  this.$message.error("开始和结束日期不能为空!");
                  this.active = 2;
                  return;
               }
               if (this.triggerId == "") {
                  this.$message.error("请选择!");
                  this.active = 2;
                  return;
               }
               // if (this.isWaitSend == "NO") {
               //   return this.active++;
               // }
               if (this.isWaitSend == "YES") {
                  if (this.time == "") {
                     this.$message.error("请选择时长!");
                     this.active = 2;
                     return;
                  }
                  if (this.timedivision == "") {
                     this.$message.error("请选择时间规格!");
                     this.active = 2;
                     return;
                  }
               }
            }
            return this.active++;
         }
         if (this.active == 3) {
            if (this.numbers == ''){
               this.$message.error("请选择邮箱类型!");
               this.active = 3
               return
            }
            if (this.username == ''){
               this.$message.error("请输入发件人!");
               this.active = 3
               return
            }
            if (this.mailtitle == ''){
               this.$message.error("请输入邮箱标签!");
               this.active = 3
               return
            }
            if (this.richtext == ''){
               this.$message.error("请输入发送内容!");
               this.active = 3
               return
            }
            this.active = 4
         }
         return;
      },
      //上一步
      laststep() {
         if (this.active-- < 1) this.active = 0;
      },

      //复制邮件
      addbegin(data) {
         let userids = ""
         const url = urlObj.createAmass;
         this.multipleclusters.map((item) => {
            this.multipleclustera.push(item.id);
         });
         let pushRules;
         if (this.Pushrules == "1") {
            pushRules = "ONCE";
            if (data !== 'DRAFT'){
               if (this.isWaitSend == "NO") {
                  data = "END";
               }
            }
         }
         if (this.Pushrules == "2") {
            pushRules = "USER_EVENT";
         }
         let Conversiontracking = "";
         if (this.Conversiontracking == true) {
            Conversiontracking = "YES";
         } else {
            Conversiontracking = "NO";
         }
         const param = {
            eventName: this.eventName,
            isConvert: Conversiontracking,
            companyId: this.companyId,
            hotelId: this.hotelId,
            state: data,
            platform: "EMAIL",
            isWaitSend: this.isWaitSend, //延迟发送
            pushRule: pushRules,
            groupIds: this.multipleclustera.join(','), //用户ID
            days: this.period, //转化周期
            targetId: this.goalid, //转化目标ID
            content:this.richtext,
            msgTemplateId:this.id,
            beginDate: this.beginDate, //触发开始时间
            endDate: this.endDate, //触发结束时间
            timeLong: this.time, //等待时间
         };
         this.$axios.post(url, param).then((res) => {
            if (res.status === 200) {
               this.$message({
                  message: "创建成功!",
                  type: "success",
               });
               this.$router.push("/mail");
            }
         });
      },
      passvalue(content) {
         this.title = content;
      },
      //转化跟踪
      onoff(bool) {
         if (bool == true) {
            this.tailafter = "YES";
         }
         if (bool == false) {
            this.tailafter = "NO";
         }
      },
   },
   filters: {
      types(data) {
         if (data == 1) {
            return "文本";
         }
         if (data == 2) {
            return "图片";
         }
         if (data == 3) {
            return "语言";
         }
         if (data == 4) {
            return "视频";
         }
      },
      Pushrulesa(data) {
         if (data == 1) {
            return "针对 48 小时内互动过的用户，仅发送一次";
         }
         if (data == 2) {
            return "基于用户事件触发";
         }
      },
   },
};
</script>

<style scoped>

ul,
li {
   list-style-type: none;
}

.boxformboard {
   width: 100%;
}
.formboard:hover {
   cursor: pointer;
}
.toolbar{width: 20%; min-height: 400px; background: #f7f7f8; float: left;}
.toolbar .titles{height: 60px; line-height: 60px; padding: 0px 1rem;}
.toolbar .titles p{font-size: 15px;}
.addresser{height: 100px; width: 100%; border-top: 1px solid #e8e8e8; padding: 1rem 1rem;}
.addressers{height: 100px; width: 100%; border-top: 1px solid #e8e8e8; padding: 1rem 1rem; margin: auto; position: relative; z-index:10;}
/deep/ .addressers .el-input__inner{background: white !important; border: 1px solid white;}
.addressers .oneemail{font-size: 14px; color: #909090;}
.addressers .el-input{margin-top: 1rem;}
/deep/ .addressers .el-input__inner:hover{border: 1px solid #679bff;}
.formboard {
   width: 17%;
   border: 1px solid #dddddd;
   height: 300px;
   margin: 2rem;
   float: left;
   position: relative;
   z-index: 50;
}
.oneemail{font-size: 14px; color: #909090;}
/deep/ .addresser .el-input__inner{background: white !important; border: 1px solid white; margin-top: 1rem;}
/deep/ .addresser .el-input__inner:hover{border: 1px solid #679bff;}
.boxformboard .formboard .multilinetext {
   width: 100%;
   height: 200px;
   background: #e2e3e7;
}
.action {
   background: #75b0ff;
   color: white;
}
.contentas {
   background: white;
   width: 80%;
   height: 160px;
   margin: auto;
   margin-top: 20px;
}
.addemailtemplate {
   width: 30%;
   border: 1px solid #dddddd;
   height: 300px;
   display: table-cell;
   vertical-align: middle;
   text-align: center;
}
.addemailtemplate .el-row i {
   font-size: 1.5rem;
}
.addemailtemplate .el-row p {
   font-size: 14px;
   color: #999999;
}
.addemailtemplate:hover {
   color: #688ff4;
   cursor: pointer;
}
.addemailtemplate:hover.addemailtemplate .el-row p {
   color: #688ff4;
}
/deep/ .el-textarea__inner {
   font-size: 12px;
}
.contentas{font-size: 12px;}
.title {
   width: 90%;
   margin: auto;
   margin-top: 2rem;
   margin-bottom: 2rem;
}
.title span {
   font-size: 18px;
}

.content {
   width: 90%;
   margin: auto;
   margin-top: 2rem;
}
.language {
   width: 100px;
   height: 30px;
   box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.13);
   line-height: 30px;
   margin-top: 1rem;
}
.figure {
   display: inline-block;
   font-size: 14px;
}
.content ul li .el-input {
   width: 300px;
   vertical-align: middle;
}
.content ul li {
   display: flex;
   line-height: 30px;
   height: 50px;
}
.content ul li span {
   margin-right: 1rem;
   font-size: 14px;
   line-height: 30px;
}
/deep/ .el-dialog__body .el-upload {
   width: 100%;
}
.search {
   margin: 1rem 0px;
   position: relative;
}
.search i {
   position: absolute;
   left: 10px;
   top: 7px;
}
/deep/ .search .el-input .el-input__inner {
   padding-left: 2rem;
}
.list {
   overflow: hidden;
   width: 100%;
   height: 240px;
   overflow-y: scroll;
}
.list p {
   line-height: 30px;
   cursor: pointer;
}
.refresh {
   width: 100%;
}
/* .nextstep{width: 90%; margin: auto;} */

.targetuser {
   width: 90%;
   height: 100%;
   margin: auto;
   margin-top: 2rem;
}
.borders {
   border-right: 1px solid #ebebeb;
   width: 20px;
   height: 50px;
   position: relative;
   float: left;
}
.borders span {
   display: inline-block;
   position: absolute;
   left: 12px;
   top: 13px;
   width: 20px;
   background: white;
   font-size: 14px;
}

.target {
   float: left;
   font-size: 14px;
   color: #a3a3a3;
   margin-top: 0.8rem;
}

.targetuser .el-row .el-select {
   margin-top: 0.3rem;
   margin-left: 1rem;
}
.add {
   width: 20%;
   display: flex;
   clear: both;
}
.add span {
   line-height: 60px;
}
.add .ioc {
   padding: 0px;
}
/deep/ .add .buttons {
   margin: 1rem 0px;
   width: 100%;
}
/deep/ .el-input__inner {
   background: #f5f5f5;
}

.numberpeople {
   margin-top: 2rem;
}
.numberpeople dl dd span {
   line-height: 60px;
}
.numberpeople dl dt {
   font-size: 14px;
   margin: 0.5rem 0px;
}

.Pushtherules {
   display: flex;
}
.Pushtherules .el-radio {
   border: 1px solid #e9e9e9;
   padding: 0.5rem;
}
.Pushtherules span {
   font-size: 14px;
   color: #a3a3a3;
   line-height: 30px;
   margin-right: 1rem;
}

.thethirdstep {
   margin: 1rem 1rem;
   margin-top: 2rem;
}
.thethirdstep dl dd {
   margin-left: 4.5rem;
   width: 90%;
   margin: auto;
   margin-top: 1rem;
}
.thethirdstep dl dd .el-select {
   width: 20%;
   margin: auto;
   padding-left: 0.5rem;
}

.Userevents span {
   font-size: 14px;
   color: #999;
   margin: 0px 10px;
}

.users {
   margin-top: 1.5rem;
   /*display: flex;*/
}
.dispatch {
   margin-top: 1.5rem;
}
.MultipleChoice {
   display: flex;
   margin-top: 2rem;
}
.MultipleChoice span {
   margin: 0px 10px;
}
.menus {
   width: 20%;
   height: 100%;
   float: left;
   margin-top: 2rem;
   background: #f7f7f7;
   text-align: center;
}
.menus .el-button {
   margin-top: 1rem;
   width: 100%;
}
.selectivetype {
   width: 50%;
   height: 100%;
   float: left;
   margin-left: 1rem;
   background: white;
   padding-left: 5rem;
   padding-top: 1rem;
}

.selectivetypes {
   margin-top: 2rem;
}
.selectivetypes span {
   font-size: 14px;
   margin-right: 1rem;
}
.el-textarea {
   width: 61%;
}
.videotitle .el-textarea {
   width: 38%;
}
.image-text .el-textarea {
   width: 62%;
   margin-top: 1rem;
}
.message {
   width: 100%;
   margin: auto;
   margin-top: 2rem;
}
.message span {
   display: inline-block;
   margin-right: 0.9rem;
   font-size: 14px;
}
.imag span {
   font-size: 14px;
   color: #999;
   line-height: 28px;
   margin-right: 1rem;
}

.upload-demo {
   width: 80px;
}
.imag {
   display: flex;
}

.video {
   margin-top: 2rem;
}
.videotitle {
   margin-top: 1rem;
}
.videotitle span {
   font-size: 14px;
   color: #999;
   margin-right: 1rem;
}
.videotitle .el-input {
   width: 285px;
}
.image-text {
   margin-top: 2rem;
}
.image-text .el-row span {
   font-size: 14px;
   color: #999;
   margin-right: 1rem;
}
.image-text .el-row .el-select {
   width: 465px;
}

.imga-title {
   margin-top: 1rem;
}
.imga-title .el-input {
   width: 465px;
}

.location {
   margin-top: 1rem;
}
.location span {
   font-size: 14px;
   color: #999;
}
.location .el-input {
   width: 465px;
}

.carte {
   margin-top: 2rem;
}
.carte .el-row {
   margin-top: 1rem;
}
.carte .el-row span {
   font-size: 14px;
   color: #999;
   margin-right: 1rem;
}

.menucontent {
   width: 72%;
   border: 1px solid #e5e5e5;
}
.menucontent .el-row .el-input {
   width: 83%;
}
.menucontent .el-row span {
   margin-left: 1rem;
   width: 25%;
   display: inline-block;
}

/deep/ .userclick .el-select {
   width: 60%;
}

.xztype span {
   font-size: 14px;
   color: #999;
}
.xztype .el-select {
   width: 60%;
}
.symbol {
   display: inline-block;
   width: 10px;
   height: 10px;
   border-radius: 50%;
   background: #ff6868;
   margin-top: 1.5rem;
   margin-left: 10px;
}
.card {
   margin-top: 2rem;
}
.card .el-row {
   margin-top: 1rem;
}
.card span {
   font-size: 14px;
   color: #999;
   margin-right: 1rem;
   width: 60px;
   display: inline-block;
}
.card .el-input {
   width: 55%;
}
.addmenu {
   width: 65%;
}
.result {
   width: 50%;
   float: left;
   height: 615px;
   background: #e5e5e5;
}
.resultimg {
   width: 50%;
   margin: auto;
   position: relative;
   height: 100%;
   background: url("../../../../assets/bgphone2.45cc4e0.png") 0px 0px no-repeat;
   background-size: cover;
}
.headportrait {
   width: 90px;
   float: left;
   height: 65px;
   background: url("../../../../assets/下载.png") 0px 0px no-repeat;
}
.advices {
   float: right;
   background: #fff;
   box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.13);
   border-radius: 10px;
   line-height: 20px;
   font-size: 12px;
   width: 65%;
   padding: 0.5rem;
}
.usermessage {
   position: absolute;
   top: 50%;
   left: 10%;
   display: flex;
}

.Theolddata {
   width: 50%;
   height: 600px;
   background: white;
   float: left;
}
.Theolddata ul {
   margin-top: 3rem;
   margin-left: 3rem;
}
.Theolddata ul li {
   line-height: 70px;
}
.Theolddata ul li span {
   font-size: 14px;
}
.designation {
   color: #999;
}
.kaishi {
   color: white;
   background: #75dfae;
   width: 100px;
}
.testequipment {
   color: white;
   background: #409eff;
}
h1 {
   font-size: 3rem;
   font-weight: bold;
}
.btn {
   padding: 0px;
}
.content ul li span {
   line-height: 22px;
   margin-left: 0.5rem;
}
.Thetotaluser {
   margin-top: 2rem;
   margin-bottom: 1rem;
}
.Thetotaluser .amount {
   margin-top: 1rem;
   display: flex;
   line-height: 40px;
}
.Thetotaluser .amount h1 {
   margin-right: 1rem;
}

.settime {
   margin: 1rem 0px;
}
.settime span {
   font-size: 14px;
}
.locations {
   width: 61.5%;
   margin-left: 1rem;
}
/deep/ .el-dialog {
   width: 25%;
}
/deep/ .el-switch.is-checked .el-switch__core {
   background-color: #409eff !important;
   border-color: #409eff !important;
}
/deep/ .el-switch__core {
   background-color: #c5ced8 !important;
   border-color: #c5ced8 !important;
}
.haddone{display: inline-block; float: left;}
@media screen and (max-width: 1366px) {
   .main {
      width: 1500px;
   }
}
</style>
